

  import {Component, Prop, Vue, Watch} from "vue-property-decorator";
  import {AttachmentDto, AttachmentHostType} from "@/api/appService";
  import api from '@/api/index';

  @Component({
    name: 'AttachmentsView'
  })
  export default class AttachmentsView extends Vue {

    @Prop({required: true})
    hostId!: number | string;

    @Prop({required: true})
    hostType!: AttachmentHostType;


    attachments: AttachmentDto[] = [];

    @Watch('hostId')
    async onHostIdChange() {
      await this.loadAttachments();
    }

    @Watch('hostType')
    async onHostTypeChange() {
      await this.loadAttachments();
    }

    async created() {
      await this.loadAttachments();
    }

    async loadAttachments() {
      await api.attachmentService.getAttachments({
        hostId: this.hostId.toString(),
        hostType: this.hostType
      }).then(res => {
        this.attachments = res;
      });
    }

    handleDownload(item: any) {
      api.attachment.download(item.id);
    }

  }

